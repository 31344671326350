<template>
    <div v-loading="Loading" element-loading-background="rgba(0, 0, 0, 0.8)" element-loading-spinner="el-icon-loading"
        element-loading-text="拼命加载中">
        <div class="main">
            <div class="right">
                <div class="user">角色：<span class="charac_name">{{ info.charac_name }}</span>
                    <a href="javascript:;" style="margin-left:10px;"><span class="bind userbtn"
                            @click="LoginDisplay = 'display:block'">登录|切换</span></a>
                </div>
                <a class="r wr" href="javascript:;" @click="getInfo"></a>
                <a class="shopbtn" href="#"><i class="cube"></i>兑换商城</a>
                <span class="victory_effect"></span>
                <span class="victory_style"></span>
                <div class="e">
                    <span class="b1 ba"></span>
                    <span class="b2 bb"></span>
                    <span class="b3 bb"></span>
                    <span class="b4 bc"></span>
                    <span class="b5 bc"></span>
                    <span class="b6 bc"></span>
                    <span class="b7 bc"></span>
                </div>
                <div class="l">
                    <span class="l1 la"></span>
                    <span class="l2 la"></span>
                    <span class="l3 la"></span>
                    <span class="l4 la"></span>
                    <span class="l5 la"></span>
                    <span class="l6 la"></span>
                    <span class="l7 la"></span>
                    <span class="l8 la"></span>
                    <span class="l9 lb"></span>
                    <span class="l10 lb"></span>
                    <span class="l11 lb"></span>
                    <span class="l12 lb"></span>
                    <span class="l13 lc"></span>
                    <span class="l14 lc"></span>
                </div>
                <div class="line"></div>
                <div class="knight_icon"></div>
                <div class="knight_eff knight_eff_0"></div>
                <div class="knight_1"></div>
                <div class="knight_eff knight_eff_1"></div>
                <div class="knight_2"></div>
                <div class="knight_eff knight_eff_2"></div>
                <div class="knight_3"></div>
                <div class="result"></div>
                <div class="result_1"></div>
                <div class="result_2"></div>
                <div class="flag_info"></div>
                <!-- <div class="copy"><span>©</span>本插件纯个人娱乐，请勿用于非法</div> -->
            </div>
            <div class="left">
                <ul class="nav">
                    <li><span>指引</span></li>
                    <li class="active"><span>骑士资料</span></li>
                    <li><span>过往战绩</span></li>
                </ul>
                <ul class="left_ul">
                    <li class="nav_1">
                        <p class="clear"></p>
                        <h2>活动规则</h2>
                        <span>下注时间 ( <font class="time_1">{{ info.bet_time }}</font> 分钟)</span><i>比赛准备 ( <font
                                class="time_2">*{{ info.last_four }}</font> 分钟)</i>
                        <span>四强赛</span><i>赛后休息 ( <font class="time_3">{{ info.hal_fof }}</font> 分钟)</i>
                        <span>半决赛</span><i>赛后休息 ( <font class="time_4">{{ info.finale }}</font> 分钟)</i>
                        <span>总决赛</span><i>冠军展示 ( <font class="time_5">{{ info.champion }}</font> 分钟)</i>
                        <span>开始新一轮</span>
                        <div class="info" style="margin-top:14px;">
                            1、下注时间内，可以选择一名骑士对他进行下注；下注后，可以对已下注的骑士进行追加下注，也可以选择其他骑士继续下注。<br><br>
                            2、新一轮竞猜开始后，会结算之前的冠军奖励；发放奖励时，会忽略小数点后的数字，取整后发放。<br><br>
                            3、通过竞猜获得的 [<font>骑士币</font>] 可以在右上角的 [<font style="padding-left:16px">
                                <p class="cube"></p>兑换商城
                            </font>] 兑换奖励奖励会通过邮件发放至当前选择的角色邮箱中，如果没收到邮箱，在游戏中切换角色重新进入即可。<br><br>

                        </div>
                    </li>
                    <li class="nav_2 active">
                        <div class="knight_gif"><img class="gif" src=""></div>
                        <div class="record">
                            <span class="record_1" title="夺冠"></span>
                            <span class="record_2" title="胜利/失败"></span>
                            <span class="record_3" title="胜率"></span>
                        </div>
                        <div class="time">
                            <span class="time_info">新一轮比赛，准备中</span>
                            <span class="progress_bar"></span>
                        </div>
                        <div class="knight_name"></div>
                        <div class="sel">
                            <div v-if="info.bet_type == 1">
                                <el-tooltip class="item" content="Top Center 提示文字" effect="dark" placement="top">
                                    <div slot="content">
                                        <p><span style="color:#abce21">骑士币获得方式：</span></p>
                                        <p>点击物品图标，在弹出中，输入兑换数量;</p>
                                    </div>
                                    <img :src="info.items_ico" style="margin-left:92.5px;width:28px;height:28px;cursor:pointer" @click="DhDisplay = true">
                                </el-tooltip>

                            </div>
                            <div v-else-if="info.bet_type == 2">
                                <el-tooltip class="item" content="Top Center 提示文字" effect="dark" placement="top-start">
                                    <div slot="content">
                                        <span v-if="info.config.roomopen == 1">
                                            <p><span style="color:#abce21">骑士币获得方式：</span></p>
                                            <p>开放时段：</p>
                                            <p v-for="(itmec, i) in info.config.roomopendisplay" :key="i">
                                                <span>每日：<span style="color:#ea0404;">{{ itmec.time }} ~ {{
                                                        itmec.enditme
                                                }}</span></span>
                                            </p>
                                        </span>
                                        <span v-if="info.config.roomopen == 0">
                                            <p><span style="color:#abce21">骑士币获得方式：</span></p>
                                            <p>开放时段：<span style="color:#ea0404;">全天</span></p>
                                        </span>
                                        <span v-if="info.config.roomtype == 0">
                                            <p>在开放时段内挑战任意一个副本，即可随机获取1~{{ info.config.num }}个骑士币</p>
                                        </span>
                                        <span v-if="info.config.roomtype == 1">
                                            <p>在开放时段内挑战当前下方的副本，即可随机获取1~{{ info.config.num }}个骑士币</p>
                                            <p>当前挑战副本：<span style="color:#ea0404;">{{ info.config.random_room_name }}
                                                </span></p>
                                        </span>
                                        <span v-if="info.config.roomtype == 2">
                                            <p>在开放时段内挑战当前下方的副本，即可随机获取1~{{ info.config.num }}个骑士币</p>
                                            <p v-for="(itmec, i) in info.config.room" :key="i">
                                                <span>副本：<span style="color:#ea0404;">{{ itmec.name }}</span></span>
                                            </p>
                                        </span>
                                    </div>
                                    <img src="../../../public/static/plug/zhan/zhan/images/10360.png"
                                        style="margin-left:92.5px;width:28px;height:28px;">
                                </el-tooltip>
                            </div>
                            <div v-else>
                                <el-tooltip class="item" content="Top Center 提示文字" effect="dark" placement="top-start">
                                    <div slot="content">
                                        <span>
                                            <p><span style="color:#abce21">骑士币获得方式：</span></p>
                                            <p>游戏商城中购买下面任意一种物品即可获得骑士币</p>
                                            <p v-for="(itmec, i) in info.config.shop" :key="i">
                                                <span>商品：<span style="color:#ea0404;">[{{ itmec.name }}] 赠骑士币：x{{
                                                        itmec.num
                                                }}</span></span>
                                            </p>
                                        </span>
                                    </div>
                                    <img src="../../../public/static/plug/zhan/zhan/images/10356.png"
                                        style="margin-left:92.5px;width:28px;height:28px;">
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="num">
                            <span>数量 :</span>
                            <input id="num" v-model="cera" maxlength="10" min="1" type="number">

                        </div>
                        <span class="num_tip">骑士币:{{ info.cera }}</span>
                        <div class="btn"><a class="cera_btn" href="javascript:;" @click="betting"></a></div>
                    </li>
                    <li class="nav_3">
                        <table>
                            <thead>
                                <tr>
                                    <th class="th_1">期数</th>
                                    <th>获胜者</th>
                                    <th class="th_3">奖励倍数</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </li>
                </ul>
            </div>
        </div>
        <div class="shop" style="display:none">
            <div class="shop_con">
                <div class="shop_title"><span>兑换商城</span><a class="x shop_colse" href="#"></a></div>
                <div class="shop_main">
                    <div class="shop_item_list">
                        <div v-for="(v, i) in info.plug_zhan_items" :key="i" class="shop_item count-item">
                            <div class="shop_item_name">{{ v.name }}</div>
                            <div class="shop_item_icon" v-on:mouseout="removeActive($event)"
                                v-on:mouseover="lj_info($event, v.code)"><img :src="v.ioc" style="width:28px;height:28px;">
                            </div>
                            <div class="shop_item_num">{{ v.cera }} 骑士币</div>
                            <div class="shop_item_info">兑换后物品发送到邮箱中</div>
                            <div class="shop_item_btn">
                                <a href="javascript:;" @click="buygmm(v.id)">兑换</a>
                            </div>
                        </div>
                    </div>
                    <div class="shop_my">
                        <div class="shop_my_cube"><i class="cube_icon"></i>骑士币: <span class="my_cera">{{ info.cera
                        }}</span></div>
                        <div class="shop_r" @click="getInfo"><a class="r rs" href="#"></a></div>
                        <div class="shop_tip">*兑换后无法撤销和退还，请确认后谨慎操作！</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="iteminfo">
            <div style="text-align:center;">物品礼包</div>
            <div v-for="(itmec, i) in itme_show_data" :key="i" style="padding:0px 6px;">{{ itmec.name }}x{{ itmec.num }}
            </div>
        </div>
        <div class="alert">
            <!-- 登陆框 -->
            <div :style="LoginDisplay" class="alert_bg">
                <div class="alert_main" style="margin-top:86px;width:350px;">
                    <div class="alert_title"><span>绑定|切换</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">游戏账号：</span><input v-model="login_info.username"
                                    class="service__input" style="background:#000;border:1px solid #442e21;color:#abce21;width: 220px;"
                                    type="text"></p>
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">游戏密码：</span><input v-model="login_info.password"
                                    class="service__input" style="background:#000;border:1px solid #442e21;color:#abce21;width: 220px;"
                                    type="password"></p>
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">绑定角色：</span>
                                <select v-model="login_info.role"
                                    style="width:173px;background:#000;border:1px solid #442e21;color:#abce21;">
                                    <option v-for="(itmec, i) in role_data" :key="i" :value="itmec.charac_no">
                                        {{ itmec.charac_name }}
                                    </option>
                                </select>
                                <span
                                    style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;cursor:pointer;"
                                    @click="getRole">获取</span>
                            </p>
                            <div>
                                <span style="padding:0px 0px;color:#f90000;"><span
                                        style="display:inline-block;padding:0px 0px;">{{
                                                LoginError
                                        }}</span></span>
                            </div>
                            <div style="text-align:center;padding:10px 0px">
                                <div class="alert_btn" style="margin:0px 0px;display:inline-block;cursor:pointer;"
                                    @click="login">确定
                                </div>
                                <div class="alert_btn" style="margin:0px 0px;display:inline-block;cursor:pointer;"
                                    @click="LoginDisplay = 'display:none'">取消</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 登陆框 end-->
            <!-- 弹出框 -->
            <div :style="MessageDisplay" class="alert_bg">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div style="text-align:center;padding:10px 0px">
                                <div class="alert_btn" style="margin:0px 0px;display:inline-block;" @click="CloseAlert">
                                    关闭</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <div :style="SuccessDisplay" class="alert_bg">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>

                            <div style="text-align:center;padding:10px 0px">
                                <div class="alert_btn" style="margin:0px 0px;display:inline-block;"
                                    @click="SuccessAlert">关闭</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>

            <div :style="BuyDisplay" class="alert_bg">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>确定要兑换？</span>
                            <div style="text-align:center;padding:10px 0px">
                                <div class="alert_btn" style="margin:0px 0px;display:inline-block;cursor:pointer;"
                                    @click="exchangeBuy(shopid)">确定</div>
                                <div class="alert_btn" style="margin:0px 0px;display:inline-block;cursor:pointer;"
                                    @click="BuyDisplay = 'display:inline-block'">
                                    取消</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>

            <div v-if="DhDisplay" class="alert_bg">
                <div class="alert_main">
                    <div class="alert_title"><span>骑士币兑换</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <div>
                                <div style="margin-bottom: 4px">
                                    <span style="color: #f00;">1个[{{info.knight_name}}]兑换1个骑士币</span>
                                </div>
                                <div style="margin-bottom: 4px;display: flex;align-items: center;margin-left: 20px;">
                                    <span style="padding: 0px 5px;">兑换数量</span>
                                    <input v-model="dh_num"
                                           max="100"
                                           placeholder="输入兑换数量"
                                           style="border: 1px solid #ffffb8;color: #fff;padding: 0px 5px;width: 166px;"
                                           type="text" />
                                </div>
                            </div>

                            <div style="text-align:center;padding:10px 0px">
                                <div class="alert_btn" style="margin:0px 0px;display:inline-block;cursor:pointer;"
                                     @click="onDh">兑换</div>
                                <div class="alert_btn" style="margin:0px 0px;display:inline-block;cursor:pointer;"
                                     @click="DhDisplay = false">
                                    取消</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>



        </div>
        <div style="display:none">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/1.gif">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/2.gif">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/3.gif">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/4.gif">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/5.gif">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/6.gif">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/7.gif">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/8.gif">
            <img class="gif" src="../../../public/static/plug/zhan/zhan/images/gif/9.gif">
        </div>
    </div>
</template>
<script>
var knight_name = ['none', '爱德华', '理查德', '罗兰', '贝奥武夫', '莱奥', '伊萨尔', '吉利特', '席恩', '兰斯洛特'];

import Vue from "vue";
import $ from "jquery";
export default {
    name: "Zhan",
    data () {
        return {
            MessageDisplay: "display:none", //弹出框显示
            SuccessDisplay: 'display:none',//成功弹出框
            LoginDisplay: "display:none", //登陆弹出框
            BuyDisplay: "display:none", //兑换
            LoginError: "",
            MessageMsg: "", //弹出框消息
            Loading: false,
            //兑换弹出框
            DhDisplay: false, //兑换弹出框
            info: {
                m_id: 0,
                charac_no: 0,
                charac_name: "",
                knight1: [],
                times: [],
                bet: [],
                time: [0, 0, 0, 0, 0],
                type: 0,
                currentTime: 0,
                game: [
                    {}, {}, {}, {}
                ],
                plug_zhan_log: [],
                plug_zhan_items: [],
                horse: [],
                bet_type: 0,
                cera: 0,
                bet_time: 0,
                last_four: 0,
                hal_fof: 0,
                finale: 0,
                champion: 0,
                knight_role: '',
                knight_name: '',
                items_ico: '',
                config: {
                    roomopen: 0,
                    num: 0,
                    roomopendisplay: [],
                    roomtype: 0,
                    room: [],
                    roomrefresh: "",
                    random_room_name: "",
                    shop: [],
                },
            },
            login_info: {
                username: "",
                password: "",
                role: "",
            },
            role_data: [],
            fighting: 0,
            fti: 0,
            d: [],
            sttime: null,
            kid: 0,
            knight_count: 0,
            timei: 0,
            gifdata: [
                "",
                require("../../../public/static/plug/zhan/zhan/images/gif/1.gif"),
                require("../../../public/static/plug/zhan/zhan/images/gif/2.gif"),
                require("../../../public/static/plug/zhan/zhan/images/gif/3.gif"),
                require("../../../public/static/plug/zhan/zhan/images/gif/4.gif"),
                require("../../../public/static/plug/zhan/zhan/images/gif/5.gif"),
                require("../../../public/static/plug/zhan/zhan/images/gif/6.gif"),
                require("../../../public/static/plug/zhan/zhan/images/gif/7.gif"),
                require("../../../public/static/plug/zhan/zhan/images/gif/8.gif"),
                require("../../../public/static/plug/zhan/zhan/images/gif/9.gif"),
            ],
            cera: 1,
            click: false,
            itme_show_data: [],
            shopid: 0,
            dh_num: 1,
        };
    },
    created () {
        this.getInfo();
    },
    mounted () {
        window.plug_zhan_log = this.info.plug_zhan_log;
        window.knightIcon = this.knightIcon
    },
    methods: {
        //登陆
        login () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/PlugLogin",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                    role: this.login_info.role,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.LoginDisplay = "display:none";
                        this.getInfo();
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //获取角色
        getRole () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/getRole",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.role_data = response.data.info;
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
       
        //获取基本配置信息
        getInfo () {
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/zhan/getZhanInfo",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        if(this.sttime != null){
                            clearTimeout(this.sttime);
                        }
                       
                        this.info = response.data.info
                        window.plug_zhan_log = this.info.plug_zhan_log;
                        this.knight_gbb(this.info)
                        this.fight()
                        this.refreshProgressBar()
                        this.getHistory()
                    } else {
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        onDh () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/zhan/itmeDh",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    dh_num: this.dh_num,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code === 200) {
                        this.DhDisplay = false;
                        this.getInfo();
                    } else {
                        this.DhDisplay = false;
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },

        
        //弹出框状态
        PopMessage (type, msg) {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
            this.MessageMsg = msg;
            if (type == false) {
                this.MessageDisplay = "display:block";
            } else {
                this.SuccessDisplay = "display:block";
            }
        },
        //关闭窗口
        CloseAlert () {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
        },
        SuccessAlert () {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
            this.getInfo();
        },
        knight_gbb (knight) {
            let icon = ''
            let flag = ''
            let eff = ''
            let line = ''
            let k = 0
            let n = 0
            let l = 0
            for (let i = 0; i < 8; i++) {
                if (knight['knight1'].length > 0) {
                    let k = knight['knight1'][i]
                    icon += '<a class="icon icon-' + k + '" id="icon-' + (i + 1) + '" data-knight="' + k + '" data-count="' + i + '" href="#"></a>';

                    let it = knight['times'][i]
                    flag += '<span class="flag flag-' + k + '" id="flag-' + (i + 1) + '"><i class="odds">' + it + '</i><i class="bet" title="' + knight['bet'][i] + '">' + this.numc(knight['bet'][i]) + '</i></span>';
                    eff += '<span class="eff eff-' + k + '" id="eff-' + (i + 1) + '"></span>';
                }
            }
            $('.knight_icon').html(icon);
            $('.flag_info').html(flag);
            $('.knight_eff_0').html(eff);

            if (this.info.type < 3) return;
            this.fighting = 1;
            icon = '';
            eff = '';
            var result = ''
            var a = 0;
            for (let i = 0; i < 8; i++) {
                let k = knight['game'][0][i];
                if (k == 1) {
                    a++;
                    icon += '<span class="knight knight-' + knight['knight1'][i] + '" id="icon-1' + a + '"></span>';
                    eff += '<span class="eff eff-' + knight['knight1'][i] + '" id="eff-1' + a + '"></span>';
                    result += '<span class="victory" id="result-' + (i + 1) + '" ></span>';
                } else {
                    result += '<span class="fail" id="result-' + (i + 1) + '" ></span>';
                }
            }
            for (let i = 0; i < 8; i++) {
                if (knight['game'][0][i] == 1) {
                    l++;
                    if (i % 2 != 0) {
                        line += '<span class="line-' + l + ' b"></span>';
                        this.d[l - 1] = 1;
                    } else {
                        line += '<span class="line-' + l + '"></span>';
                        this.d[l - 1] = 0;
                    }
                }
            }

            $('.line').html(line);
            $('.knight_1').html(icon);
            $('.knight_eff_1').html(eff);
            $('.result').html(result);
            if (this.info.type < 4) return;
            this.d = [];
            this.fighting = 2;
            icon = eff = result = ''; n = a = 0;
            for (let i = 0; i < 8; i++) {
                k = knight['game'][1][i];
                if (k == 0 && knight['game'][0][i] == 0) continue;
                n++;
                if (k == 1) {
                    a++;
                    icon += '<span class="knight knight-' + knight['knight1'][i] + '" id="icon-2' + a + '"></span>';
                    eff += '<span class="eff eff-' + knight['knight1'][i] + '" id="eff-2' + a + '"></span>';
                    result += '<span class="victory" id="result-1' + n + '" ></span>';
                } else {
                    result += '<span class="fail" id="result-1' + n + '" ></span>';
                }
            }
            for (let i = 0; i < 2; i++) {
                l++;
                if (knight['game'][1][i * 4 + 0] + knight['game'][1][i * 4 + 1] > knight['game'][1][i * 4 + 2] + knight['game'][1][i * 4 + 3]) {
                    line += '<span class="line-' + l + '"></span>';
                    this.d[i] = 0;
                } else {
                    line += '<span class="line-' + l + ' b"></span>';
                    this.d[i] = 1;
                }
            }
            $('.line').html(line);
            $('.knight_2').html(icon);
            $('.knight_eff_2').html(eff);
            $('.result_1').html(result);
            if (this.info.type < 5) return;
            this.d = [];
            this.fighting = 3;
            $('.knight-e').addClass('over');
            icon = eff = result = ''; n = a = 0;
            var b = '';
            for (let i = 0; i < 8; i++) {
                k = knight['game'][2][i];
                if (k == 0 && knight['game'][1][i] == 0) continue;
                l++;
                n++;
                if (k == 1) {
                    a++;
                    icon += '<span class="knight knight-' + knight['knight1'][i] + '" id="icon-3' + a + '"></span>';
                    eff += '<span class="eff eff-' + knight['knight1'][i] + '" id="eff-3' + a + '"></span>';
                    result += '<span class="victory" id="result-2' + n + '" ></span>';
                } else {
                    result += '<span class="fail" id="result-2' + n + '" ></span>';
                }
            }
            if (knight['game'][2][0] + knight['game'][2][1] + knight['game'][2][2] + knight['game'][2][3] > 0) {
                this.d[0] = 0;
                b = '';
            } else {
                this.d[0] = 1;
                b = ' b';
            }
            line += '<span class="line-7' + b + '"></span>';
            $('.knight_3').html(icon);
            $('.knight_eff_3').html(eff);
            $('.result_2').html(result);
            $('.line').html(line);
        },
        fight () {
            if (!this.fighting) return;
            if (this.fighting == 1) {
                $('.knight_1').css('display', 'none');
                $('.knight_eff_1').css('display', 'none');
                $('.result').css('display', 'none');
                $('.line-1').css('display', 'none');
                $('.line-2').css('display', 'none');
                $('.line-3').css('display', 'none');
                $('.line-4').css('display', 'none');
                $('.bc').css('display', 'block');
                $('.la').css('display', 'block');
            } else if (this.fighting == 2) {
                $('.knight_2').css('display', 'none');
                $('.knight_eff_2').css('display', 'none');
                $('.result_1').css('display', 'none');
                $('.line-5').css('display', 'none');
                $('.line-6').css('display', 'none');
                $('.bb').css('display', 'block');
                $('.lb').css('display', 'block');
            } else if (this.fighting == 3) {
                $('.victory_effect').css('display', 'none');
                $('.victory_style').css('display', 'none');
                $('.knight_3').css('display', 'none');
                $('.knight_eff_3').css('display', 'none');
                $('.result_2').css('display', 'none');
                $('.line-7').css('display', 'none');
                $('.ba').css('display', 'block');
                $('.lc').css('display', 'block');
            }
            this.ft();
        },
        ft () {
            if (!this.fighting) return;
            if (this.fti == 0) {
                for (let i = 1; i < 15; ++i) {
                    $('.l' + i).width(34);
                }
            } else {
                if (this.fighting == 1) {
                    for (let i = 0; i < this.d.length; ++i) {
                        if (this.d[i] == 0) {
                            $('.l' + (i * 2 + 1)).width($('.l' + (i * 2 + 1)).width() - this.RandomNumi(0));
                            $('.l' + (i * 2 + 2)).width($('.l' + (i * 2 + 2)).width() - this.RandomNumi(1));
                            if (this.fti >= 9) $('.l' + (i * 2 + 2)).width(0);
                        } else {
                            $('.l' + (i * 2 + 1)).width($('.l' + (i * 2 + 1)).width() - this.RandomNumi(1));
                            if (this.fti >= 9) $('.l' + (i * 2 + 1)).width(0);
                            $('.l' + (i * 2 + 2)).width($('.l' + (i * 2 + 2)).width() - this.RandomNumi(0));
                        }
                    }
                } else if (this.fighting == 2) {
                    for (let i = 0; i < this.d.length; ++i) {
                        if (this.d[i] == 0) {
                            $('.l' + (i * 2 + 9)).width($('.l' + (i * 2 + 9)).width() - this.RandomNumi(0));
                            $('.l' + (i * 2 + 10)).width($('.l' + (i * 2 + 10)).width() - this.RandomNumi(1));
                            if (this.fti >= 9) $('.l' + (i * 2 + 10)).width(0);
                        } else {
                            $('.l' + (i * 2 + 9)).width($('.l' + (i * 2 + 9)).width() - this.RandomNumi(1));
                            if (this.fti >= 9) $('.l' + (i * 2 + 9)).width(0);
                            $('.l' + (i * 2 + 10)).width($('.l' + (i * 2 + 10)).width() - this.RandomNumi(0));
                        }
                    }
                } else if (this.fighting == 3) {
                    if (this.d[0] == 0) {
                        $('.l13').width($('.l13').width() - this.RandomNumi(0));
                        $('.l14').width($('.l14').width() - this.RandomNumi(1));
                        if (this.fti >= 9) $('.l14').width(0);
                    } else {
                        $('.l13').width($('.l13').width() - this.RandomNumi(1));
                        if (this.fti >= 9) $('.l13').width(0);
                        $('.l14').width($('.l14').width() - this.RandomNumi(0));
                    }
                }
            }
            if (this.fti < 10) {
                this.fti++;
                setTimeout(this.ft, 1000);
            } else {
                $('.knight_1').css('display', 'block');
                $('.knight_eff_1').css('display', 'block');
                $('.result').css('display', 'block');
                $('.line-1').css('display', 'block');
                $('.line-2').css('display', 'block');
                $('.line-3').css('display', 'block');
                $('.line-4').css('display', 'block');
                $('.knight_2').css('display', 'block');
                $('.knight_eff_2').css('display', 'block');
                $('.result_1').css('display', 'block');
                $('.line-5').css('display', 'block');
                $('.line-6').css('display', 'block');
                $('.knight_3').css('display', 'block');
                $('.knight_eff_3').css('display', 'block');
                $('.result_2').css('display', 'block');
                $('.line-7').css('display', 'block');
                $('.ba').css('display', 'none');
                $('.bb').css('display', 'none');
                $('.bc').css('display', 'none');
                $('.la').css('display', 'none');
                $('.lb').css('display', 'none');
                $('.lc').css('display', 'none');
                if (this.fighting == 3) {
                    $('.victory_style').css('display', 'block');
                    $('.victory_effect').css('display', 'block');
                }
                this.fti = 0;
                this.fighting = 0;
            }
        },
        numc (num) {
            if (num < 10000) return num;
            num = parseFloat((num / 10000).toFixed(1));
            if (num < 10) return num + '万';
            if (num >= 10 && num < 99) return num.toFixed(0) + '万';
            num = (num / 100).toFixed(0);
            if (num < 10) return num + '百万';
            num = (num / 10).toFixed(0);
            if (num < 10) return num + '千万';
            return (num / 10).toFixed(0) + '亿';
        },
        RandomNumi (typ) {
            if (typ) return this.RandomNum(2, 4) + (this.RandomNum(0, 9) / 10);
            else return this.RandomNum(0, 3) + (this.RandomNum(0, 9) / 10);
        },
        RandomNum (Min, Max) {
            var Range = Max - Min;
            var Rand = Math.random();
            var num = Min + Math.round(Rand * Range);
            return num;
        },
        refreshProgressBar () {
            this.sttime = setTimeout(this.refreshProgressBar, 1000);
            this.ProgressBar();
        },
        ProgressBar () {
            let ti = ['赛前准备 下注阶段', '即将开始 四强赛', '即将开始 半决赛', '即将开始 决赛', '即将开始下一轮竞猜'];
            var time = this.info.time; //获取当前数组时间
            console.log(time);
            var timei = this.info.currentTime;
            if (timei >= time[this.info.type - 1]) {
                console.log(this.info.type);
                if (this.info.type == 5) {
                    $('.knight_icon').html('');
                    $('.flag_info').html('');
                    $('.knight_eff_0').html('');


                    $('.line').html('');
                    $('.knight_1').html('');
                    $('.knight_eff_1').html('');
                    $('.result').html('');


                    $('.line').html('');
                    $('.knight_2').html('');
                    $('.knight_eff_2').html('');
                    $('.result_1').html('');


                    $('.knight_3').html('');
                    $('.knight_eff_3').html('');
                    $('.result_2').html('');
                    $('.line').html('');

                    $('.victory_style').css('display', 'none');
                    $('.victory_effect').css('display', 'none');
                }
                clearTimeout(this.sttime);
                this.getInfo();
            } else {
                $('.time_info').html(ti[this.info.type - 1]);
                if (timei / time[this.info.type - 1] * 120 == "NaN") {
                    this.ProgressBar();
                }
                $('.progress_bar').attr('style', 'width:' + timei / time[this.info.type - 1] * 120 + 'px');
                this.info.currentTime += 1;
            }
        },
        getHistory () {
            var dat = this.info.plug_zhan_log;
            let html = '';
            for (let i = 0; i < dat.length; i++) {
                let name = '';
                if(dat[i]['charac_name'] !== ""){
                    name = ' - ' + dat[i]['charac_name'];
                }
                html += '<tr><td>' + dat[i]['period'] + '</td><td class="td"><span class="knighticon knighticon-' + dat[i]['zhan_id'] + '">' + knight_name[dat[i]['zhan_id']]+ name + '</span></td><td>' + dat[i]['times'] + '</td></tr>';
            }
            $('tbody').html(html);
        },
        knightIcon (kid, knight_count) {

            this.kid = kid;
            this.knight_count = knight_count;
            $('.nav li').removeClass('active');
            $('.left_ul li').removeClass('active');
            $('.nav li').eq(1).addClass('active');
            $('.left_ul li').eq(1).addClass('active');
            //动态图片
            $('.gif').attr('src', this.gifdata[this.kid]);
            $('.knight_name').html(knight_name[this.kid]);
            if (this.kid > 0) {
                let k = this.info.horse;
                if (this.kid == 1) {
                    $('.record_1').html(k.no1['win'] + '胜');
                    let win = k.no1['history'] / (parseInt(k.no1['history']) + parseInt(k.no1['lose'])) * 100;
                    $('.record_2').html(k.no1['history'] + '/' + k.no1['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                } else if (this.kid == 2) {
                    $('.record_1').html(k.no2['win'] + '胜');
                    let win = k.no2['history'] / (parseInt(k.no2['history']) + parseInt(k.no2['lose'])) * 100;
                    $('.record_2').html(k.no2['history'] + '/' + k.no2['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                } else if (this.kid == 3) {
                    $('.record_1').html(k.no3['win'] + '胜');
                    let win = k.no3['history'] / (parseInt(k.no3['history']) + parseInt(k.no3['lose'])) * 100;
                    $('.record_2').html(k.no3['history'] + '/' + k.no3['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                } else if (this.kid == 4) {
                    $('.record_1').html(k.no4['win'] + '胜');
                    let win = k.no4['history'] / (parseInt(k.no4['history']) + parseInt(k.no4['lose'])) * 100;
                    $('.record_2').html(k.no4['history'] + '/' + k.no4['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                } else if (this.kid == 5) {
                    $('.record_1').html(k.no5['win'] + '胜');
                    let win = k.no5['history'] / (parseInt(k.no5['history']) + parseInt(k.no5['lose'])) * 100;
                    $('.record_2').html(k.no5['history'] + '/' + k.no5['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                } else if (this.kid == 6) {
                    $('.record_1').html(k.no6['win'] + '胜');
                    let win = k.no6['history'] / (parseInt(k.no6['history']) + parseInt(k.no6['lose'])) * 100;
                    $('.record_2').html(k.no6['history'] + '/' + k.no6['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                } else if (this.kid == 7) {
                    $('.record_1').html(k.no7['win'] + '胜');
                    let win = k.no7['history'] / (parseInt(k.no7['history']) + parseInt(k.no7['lose'])) * 100;
                    $('.record_2').html(k.no7['history'] + '/' + k.no7['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                } else if (this.kid == 8) {
                    $('.record_1').html(k.no8['win'] + '胜');
                    let win = k.no8['history'] / (parseInt(k.no8['history']) + parseInt(k.no8['lose'])) * 100;
                    $('.record_2').html(k.no8['history'] + '/' + k.no8['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                } else if (this.kid == 9) {
                    $('.record_1').html(k.no9['win'] + '胜');
                    let win = k.no9['history'] / (parseInt(k.no9['history']) + parseInt(k.no9['lose'])) * 100;
                    $('.record_2').html(k.no9['history'] + '/' + k.no9['lose']);
                    $('.record_3').html(parseInt(win) + '%');
                }
            }
        },
        //下注
        betting () {
            if (this.kid < 1) {
                this.PopMessage(false, '请先选择下注的骑士。')
                return false
            } else if (this.cera < 1) {
                this.PopMessage(false, '请确认下注的数量是否正确。')
                return false
            }
            if (this.info.charac_no < 1) {
                this.PopMessage(false, '请先登录并且绑定角色')
                return false;
            }
            if (this.click == true) {
                return false;
            }
            clearTimeout(this.sttime); //销毁定时器
            this.Loading = true;
            this.click = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/zhan/ZhanBetting",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    num: this.cera,
                    horse: this.knight_count,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    this.click = false
                    if (response.data.code == 200) {
                        this.PopMessage(true, response.data.msg)
                    } else {
                        this.click = false;
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        lj_info ($event, itmes) {
            this.itme_show_data = itmes
            var container = document.getElementsByClassName("iteminfo");
            if (typeof (container) != "undefined") {
                container[0]
                var x = $event.x
                var y = $event.y

                if (x > 1167) {
                    console.log("11")
                    container[0].style.left = (x - 77) + 'px'
                } else {
                    container[0].style.left = (x - 73) + 'px'
                }
                container[0].style.top = y + 'px'
                container[0].style.display = 'block'
                let item_h = container[0].offsetHeight + container[0].style.top;
                if (item_h > 435) {
                    y -= (item_h - 435);
                    container[0].style.top = y + 'px'
                }
            }
        },
        removeActive ($event) {
            console.log($event)
            var container = document.getElementsByClassName("iteminfo");
            if (typeof (container) != "undefined") {
                container[0].style.display = 'none'
            }
        },
        buygmm (ids) {
            this.shopid = ids
            this.BuyDisplay = "display:block";
        },
        exchangeBuy (ids) {
            this.BuyDisplay = 'display:none'
            if (this.click == true) {
                return false
            }
            clearTimeout(this.sttime); //销毁定时器
            this.Loading = true;
            this.click = true;

            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/zhan/ZhanBuy",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    id: ids,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    this.click = false
                    if (response.data.code == 200) {
                        this.PopMessage(true, response.data.msg)
                    } else {
                        this.click = false;
                        this.PopMessage(false, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });

        },
    },
};

$(function () {
    $('.knight_icon').on('click', 'a', function () {
        var kid = $(this).data('knight');
        var knight_count = $(this).data('count');
        window.knightIcon(kid, knight_count);

    });
    $('.nav').on('click', 'li', function () {
        let index = $('li').index(this);
        $('li').removeClass('active');
        $('.left_ul li').removeClass('active');
        $(this).addClass('active');
        $('.left_ul li').eq(index).addClass('active');
        if (index == 2) getHistory();
    });



    $('.shopbtn').on('click', function () {
        $('.shop').show();
    });
    $('.shop_colse').on('click', function () {
        $('.shop').hide();
    });







    function getHistory () {
        var dat = window.plug_zhan_log;
        let html = '';
        for (let i = 0; i < dat.length; i++) {
            let name = '';
            if(dat[i]['charac_name'] !== ""){
                name = ' - ' + dat[i]['charac_name'];
            }
            html += '<tr><td>' + dat[i]['period'] + '</td><td class="td"><span class="knighticon knighticon-' + dat[i]['zhan_id'] + '">' + knight_name[dat[i]['zhan_id']] + name + '</span></td><td>' + dat[i]['times'] + '</td></tr>';
        }
        $('tbody').html(html);
    }
});
</script>
<style scoped>
@import "../../../public/static/plug/zhan/zhan/css/style.css";
</style>
<style>
.shop_item_btn {
    background: url('../../../public/static/plug/zhan/zhan/images/shop_btn.png');
    position: absolute;
    bottom: 1px;
    left: 1px;
    height: 22px;
    line-height: 22px;
    width: 189px;
    text-align: center;
    border-radius: 0 0 0 3px
}

.shop_item_btn:hover {
    background-position: 0 -21px
}

.shop_item_btn a {
    color: #22dd78;
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    display: block
}
</style>


<style>
    #root,
    body,
    html {
        min-width: 0px;
    }
    </style>